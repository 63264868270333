
import "./saved.scss"
//import  Post from "./com";
//import SubmitPost from "../Components/Post/SubmitPost";
//import RightBar from "../Components/Rightbar/Rightbar";
//import Post from "../Post/Post";
import Saving from "../Post/Saving"

const Saved = () => {
    return (
        <>
            <div className="save">
            
              <Saving /> {/**/} 
            </div>
            
        </>
    )
}

export default Saved