
import "./account.scss"

//import Teams from "../Post/Teams"
//import Saving from "../Post/Saving"
import Accounts from "./Accounts"

const Account = () => {
    return (
        <>
            <div className="home">
            
              <Accounts /> {/**/} 
            </div>
            
        </>
    )
}

export default Account